import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomeView from './views/Home/Home.view'
import JobView from './views/Job/Job.view'
import ThanksView from './views/Thanks/Thanks.view'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <HomeView />
        </Route>
        <Route path="/vaga/:uri">
          <JobView />
        </Route>
        <Route path="/obrigado">
          <ThanksView />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

