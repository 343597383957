import styled from 'styled-components'

export const Container = styled.div`
    text-align: center;
`

export const TextContainer = styled.p `
    color: ${props => props.color};
    font-size: 16px;
    text-align: left;
    padding-bottom: 16px;
    cursor: pointer;
    color: violet;

    &:hover{
        color: #FFF;
    }

    @media (min-width: 768px) {
        font-size: 20px;
    }
`

export const DivRow = styled.div`
    width: 300px;
    margin: 30px auto 0 auto;

    @media (min-width: 768px) {
        width: 333px;
    }
    
    @media (min-width: 1280px) {
        margin: 50px auto 0 auto;
        width: 1042px;
        display: flex;
        justify-content: space-between;
    }

    @media (min-width: 1440px) {
        width: 1210px;
    }
`

export const DivColumn = styled.div`

`

export const Title = styled.h2 `
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 32px;
`

export const Input = styled.input `
    display: block;
    height: 33px;
    margin: 0 auto 10px auto;
    padding: 0 20px;
    background-color: #730082;
    color: #F370F3;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    text-align: center;

    &::placeholder {
        color: #F370F3;
        font-size: 16px;
    }

    @media (min-width: 768px) {
        height: 66px;
        border-radius: 10px;
        font-size: 24px;
    }

    @media (min-width: 1440px) {
        padding: 0 35px;
        width: 390px;
    }
`

export const Select = styled.select `
    display: block;
    width: 235px;
    height: 33px;
    padding: 0 20px;
    background-color: #730082;
    color: #F370F3;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    text-align-last: center;
    margin: 0 auto 10px auto; 

    &::-webkit-input-placeholder {
        color: #023867;
        font-weight: 300;
    }

    @media (min-width: 768px) {
        width: 332px;
        height: 66px;
        border-radius: 10px;
        padding-right: 56px;
    }

    @media (min-width: 1440px) {
        width: 390px;
    }
`

export const Option = styled.option `

`

export const JobsWrapper = styled.div `
    margin: 20px auto 0 auto;
    max-width: 221px;

    @media (min-width: 768px) {
        max-width: 332px;
    }

    @media (min-width: 1280px) {
        max-width: 1042px;
    }

    @media (min-width: 1440px) {
        max-width: 1210px;
    }
`

export const DividerPink = styled.div `
    height: 2px;
    width: 100%;
    background-color: #FF00FF;
    margin: 20px auto;
`