import React from 'react'
import MetaTags from 'react-meta-tags';
import { 
    Container,
    Wrapper,
    JobDescription,
    JobName,
    JobLocal,
    JobLocalText,
    PinIcon,
    Button,
    ShareText
 } from './JobHeader.styles'
 import {
    FacebookShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TelegramShareButton
 } from "react-share";
 import pinIcon from '../../assets/icon-local.svg'
 import './Demo.css'

function JobHeader ( {jobName, local, scrollToRef} ) {

    return <Container>
        <MetaTags>
            <title>{jobName}</title>
            <meta property="og:title" content={jobName + " - Vem Pra TQI!"} />
        </MetaTags>
        <Wrapper>
            <JobDescription>
                <JobName>{jobName}</JobName>
                <JobLocal>
                    <PinIcon src={pinIcon} />
                    <JobLocalText>{local}</JobLocalText>
                </JobLocal>
            </JobDescription>
            <Button type="button" onClick={() => scrollToRef()}>Quero me candidatar</Button>
        </Wrapper>

        <ShareText>Compartilhe com um amigo</ShareText>
        <div className="Demo__container">
            <div className="Demo__some-network">
                <LinkedinShareButton url={window.location.href} className="Demo__some-network__share-button">
                    <div className="Demo__network__fill-wrapper">
                        <svg className="Demo__network__fill" id="Grupo_52" data-name="Grupo 52" xmlns="http://www.w3.org/2000/svg" width="36.371" height="36.387" viewBox="0 0 36.371 36.387" >
                            <g id="Grupo_51" data-name="Grupo 51">
                                <rect id="Retângulo_39" data-name="Retângulo 39" width="7.509" height="24.258" transform="translate(0.721 12.129)" />
                                <path id="Caminho_326" data-name="Caminho 326" d="M330.314,199.808a4.476,4.476,0,1,0-4.44-4.477A4.46,4.46,0,0,0,330.314,199.808Z" transform="translate(-325.874 -190.857)" />
                            </g>
                            <path id="Caminho_327" data-name="Caminho 327" d="M339.912,209.095c0-3.409,1.569-5.441,4.574-5.441,2.763,0,4.089,1.95,4.089,5.441v12.734h7.474V206.469c0-6.5-3.683-9.639-8.827-9.639a8.456,8.456,0,0,0-7.31,4.008v-3.266h-7.2v24.257h7.2Z" transform="translate(-319.678 -185.442)" />
                        </svg>
                    </div>
                </LinkedinShareButton>
            </div>

            <div className="Demo__some-network">
                <WhatsappShareButton
                    url={window.location.href}
                    title={jobName}
                    separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <div className="Demo__network__fill-wrapper">
                        <svg className="Demo__network__fill" xmlns="http://www.w3.org/2000/svg" width="39.444" height="39.453" viewBox="0 0 39.444 39.453">
                            <g id="Grupo_159" data-name="Grupo 159" transform="translate(-264.958 -353.743)">
                                <g id="Grupo_158" data-name="Grupo 158" transform="translate(264.958 353.743)">
                                <path id="Caminho_725" data-name="Caminho 725" d="M304.393,372.472a19.368,19.368,0,0,0-38.7-.353c-.009.282-.018.563-.018.843a19.067,19.067,0,0,0,2.773,9.927l-3.49,10.308,10.725-3.409a19.39,19.39,0,0,0,28.72-16.826C304.4,372.8,304.4,372.636,304.393,372.472Zm-19.355,16.644a16.216,16.216,0,0,1-8.957-2.673l-6.255,1.994,2.03-6a16,16,0,0,1-3.1-9.474,15.366,15.366,0,0,1,.08-1.568,16.288,16.288,0,0,1,32.427.308c.036.417.055.834.055,1.26A16.236,16.236,0,0,1,285.038,389.116Z" transform="translate(-264.958 -353.743)" fillRule="evenodd"/>
                                <path id="Caminho_726" data-name="Caminho 726" d="M290.293,373.286c-.481-.235-2.811-1.378-3.246-1.531s-.752-.237-1.069.235-1.233,1.531-1.506,1.841-.552.353-1.033.118a12.869,12.869,0,0,1-3.816-2.34,14.042,14.042,0,0,1-2.638-3.254c-.271-.472-.027-.726.208-.961.218-.217.472-.553.717-.825.064-.082.117-.155.171-.227a4.973,4.973,0,0,0,.3-.561.851.851,0,0,0-.035-.825c-.118-.237-1.07-2.556-1.469-3.5s-.788-.781-1.069-.781-.59-.044-.907-.044a1.76,1.76,0,0,0-1.269.588,5.278,5.278,0,0,0-1.659,3.935,6.028,6.028,0,0,0,.244,1.606,10.74,10.74,0,0,0,1.7,3.263c.235.308,3.281,5.221,8.1,7.116,4.832,1.877,4.832,1.251,5.7,1.169a4.735,4.735,0,0,0,3.2-2.238,3.9,3.9,0,0,0,.28-2.24C291.082,373.639,290.763,373.521,290.293,373.286Z" transform="translate(-261.337 -350.187)" fillRule="evenodd"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </WhatsappShareButton>
            </div>

            <div className="Demo__some-network">
                <TelegramShareButton
                    url={window.location.href}
                    title={jobName}
                    className="Demo__some-network__share-button"
                >
                    <div className="Demo__network__fill-wrapper">
                        <svg className="Demo__network__fill" xmlns="http://www.w3.org/2000/svg" width="42.592" height="35.318" viewBox="0 0 42.592 35.318">
                            <g id="Grupo_157" data-name="Grupo 157" transform="translate(-104.763 -306.88)">
                                <line id="Linha_7" data-name="Linha 7" x2="4.179" y2="11.283" transform="translate(116.429 328.1)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path id="Caminho_723" data-name="Caminho 723" d="M135.99,352.48l-.418,9.313,5.993-5.082Z" transform="translate(-14.905 -22.29)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path id="Caminho_724" data-name="Caminho 724" d="M144.677,307.982l-38.122,14.485a1.228,1.228,0,0,0,.043,2.311l9.831,3.322,22.386-13.446-17.73,15.536,13.8,10.474a2.512,2.512,0,0,0,3.955-1.392l7.461-29.845A1.228,1.228,0,0,0,144.677,307.982Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                        </svg>
                    </div>
                </TelegramShareButton>
            </div>

            <div className="Demo__some-network">
                <FacebookShareButton
                    url={window.location.href}
                    quote={jobName}
                    className="Demo__some-network__share-button"
                >
                    <div className="Demo__network__fill-wrapper">
                        <svg className="Demo__network__fill" xmlns="http://www.w3.org/2000/svg" width="17.914" height="38.81" viewBox="0 0 17.914 38.81">
                            <path id="Caminho_330" data-name="Caminho 330" d="M388.742,174.843h7.814v-19.57h5.452l.582-6.552h-6.034v-3.732c0-1.545.31-2.156,1.805-2.156h4.229v-6.8h-5.412c-5.815,0-8.436,2.561-8.436,7.463v5.225h-4.066v6.635h4.066Z" transform="translate(-384.676 -136.033)" />
                        </svg>
                    </div>
                </FacebookShareButton>
            </div>
        </div>
    </Container>
}

export default JobHeader