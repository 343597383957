import React from "react";
import { useHistory } from "react-router-dom"
import logotqi from "../../assets/logo-tqi-colorida.png";
import { 
    Container, 
    IconWrapper, 
    SocialIcons, 
    Title,
    Icon,
    LogoTQI
} from "./Footer.style";

function Footer() {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }

  return (
    <Container>
        <Title>Conheça mais sobre a TQI.</Title>
        <SocialIcons>
            <IconWrapper>
                <a href="https://www.tqi.com.br" target="_blank" rel="noreferrer">
                    <Icon alt="globe logo" xmlns="http://www.w3.org/2000/svg" width="46.258" height="46.258" viewBox="0 0 46.258 46.258">
                        <path id="Caminho_344" data-name="Caminho 344" d="M457.049,297.179a23.129,23.129,0,1,0,23.13,23.13A23.129,23.129,0,0,0,457.049,297.179Zm0,4.023A18.994,18.994,0,0,1,468.7,305.2a23.381,23.381,0,0,1-9.611,7.092,89.02,89.02,0,0,0-6.722-10.48A18.98,18.98,0,0,1,457.049,301.2Zm-8.619,2.078a85.875,85.875,0,0,1,6.818,10.372,61.347,61.347,0,0,1-16.739,2.133A19.156,19.156,0,0,1,448.43,303.28Zm-10.486,17.027c0-.164.023-.326.027-.492a64.553,64.553,0,0,0,19.176-2.549q.672,1.373,1.291,2.761-9.769,3.054-16.068,12.49A19.019,19.019,0,0,1,437.944,320.307Zm19.105,19.109a19,19,0,0,1-11.725-4.053c3.8-5.954,8.642-9.828,14.644-11.616a75.6,75.6,0,0,1,3.939,14.377A18.983,18.983,0,0,1,457.049,339.416Zm10.606-3.226a79.5,79.5,0,0,0-3.7-13.292,38.371,38.371,0,0,1,11.946.379A19.115,19.115,0,0,1,467.655,336.19Zm-5.241-17.1c-.471-1.085-.957-2.168-1.479-3.237a27.353,27.353,0,0,0,10.7-7.868,19.03,19.03,0,0,1,4.473,11.247A41.284,41.284,0,0,0,462.414,319.091Z" transform="translate(-433.921 -297.179)" />
                    </Icon>
                </a>
            </IconWrapper>

            <IconWrapper>
                <a href="https://www.linkedin.com/company/tqi-it/mycompany/" target="_blank" rel="noreferrer">
                    <Icon id="Grupo_52" data-name="Grupo 52" xmlns="http://www.w3.org/2000/svg" width="36.371" height="36.387" viewBox="0 0 36.371 36.387">
                        <g id="Grupo_51" data-name="Grupo 51">
                            <rect id="Retângulo_39" data-name="Retângulo 39" width="7.509" height="24.258" transform="translate(0.721 12.129)"/>
                            <path id="Caminho_326" data-name="Caminho 326" d="M330.314,199.808a4.476,4.476,0,1,0-4.44-4.477A4.46,4.46,0,0,0,330.314,199.808Z" transform="translate(-325.874 -190.857)" />
                        </g>
                        <path id="Caminho_327" data-name="Caminho 327" d="M339.912,209.095c0-3.409,1.569-5.441,4.574-5.441,2.763,0,4.089,1.95,4.089,5.441v12.734h7.474V206.469c0-6.5-3.683-9.639-8.827-9.639a8.456,8.456,0,0,0-7.31,4.008v-3.266h-7.2v24.257h7.2Z" transform="translate(-319.678 -185.442)" />
                    </Icon>
                </a>
            </IconWrapper>

            <IconWrapper>
                <a href="https://www.instagram.com/tqi.it/"  target="_blank" rel="noreferrer">
                    <Icon xmlns="http://www.w3.org/2000/svg" width="39.787" height="39.787" viewBox="0 0 39.787 39.787">
                        <g id="Grupo_161" data-name="Grupo 161" transform="translate(-945.893 -7370.646)">
                            <path id="Caminho_332" data-name="Caminho 332" d="M455.179,140.685c5.312,0,5.941.021,8.04.116a11.02,11.02,0,0,1,3.693.686,6.575,6.575,0,0,1,3.775,3.775,11.021,11.021,0,0,1,.686,3.693c.1,2.1.116,2.728.116,8.04s-.021,5.941-.116,8.038a11.024,11.024,0,0,1-.686,3.695,6.575,6.575,0,0,1-3.775,3.775,10.984,10.984,0,0,1-3.693.684c-2.1.1-2.726.116-8.04.116s-5.941-.021-8.038-.116a11,11,0,0,1-3.695-.684,6.589,6.589,0,0,1-3.775-3.775,10.987,10.987,0,0,1-.685-3.695c-.1-2.1-.116-2.726-.116-8.038s.021-5.941.116-8.04a10.984,10.984,0,0,1,.685-3.693,6.589,6.589,0,0,1,3.775-3.775,11.036,11.036,0,0,1,3.695-.686c2.1-.1,2.726-.116,8.038-.116m0-3.584c-5.4,0-6.08.025-8.2.12a14.616,14.616,0,0,0-4.829.925,10.173,10.173,0,0,0-5.817,5.819,14.565,14.565,0,0,0-.925,4.829c-.1,2.122-.12,2.8-.12,8.2s.023,6.08.12,8.2a14.566,14.566,0,0,0,.925,4.829,10.173,10.173,0,0,0,5.817,5.819,14.665,14.665,0,0,0,4.829.925c2.122.1,2.8.118,8.2.118s6.08-.023,8.2-.118a14.665,14.665,0,0,0,4.829-.925,10.184,10.184,0,0,0,5.819-5.819,14.617,14.617,0,0,0,.925-4.829c.1-2.122.12-2.8.12-8.2s-.023-6.08-.12-8.2a14.617,14.617,0,0,0-.925-4.829,10.184,10.184,0,0,0-5.819-5.819,14.616,14.616,0,0,0-4.829-.925c-2.122-.1-2.8-.12-8.2-.12" transform="translate(510.607 7233.544)" />
                            <path id="Caminho_333" data-name="Caminho 333" d="M450.577,142.178a10.215,10.215,0,1,0,10.215,10.215,10.216,10.216,0,0,0-10.215-10.215m0,16.846a6.631,6.631,0,1,1,6.631-6.631,6.631,6.631,0,0,1-6.631,6.631" transform="translate(515.208 7238.147)"/>
                            <path id="Caminho_334" data-name="Caminho 334" d="M454.812,143.1a2.387,2.387,0,1,1-2.387-2.387,2.387,2.387,0,0,1,2.387,2.387" transform="translate(523.98 7236.82)"/>
                        </g>
                    </Icon>
                </a>
            </IconWrapper>

            <IconWrapper>
                <a href="https://www.facebook.com/tqi.it"  target="_blank" rel="noreferrer">
                    <Icon xmlns="http://www.w3.org/2000/svg" width="17.914" height="38.81" viewBox="0 0 17.914 38.81">
                        <path id="Caminho_330" data-name="Caminho 330" d="M388.742,174.843h7.814v-19.57h5.452l.582-6.552h-6.034v-3.732c0-1.545.31-2.156,1.805-2.156h4.229v-6.8h-5.412c-5.815,0-8.436,2.561-8.436,7.463v5.225h-4.066v6.635h4.066Z" transform="translate(-384.676 -136.033)" />
                    </Icon>
                </a>
            </IconWrapper>

            <IconWrapper>
                <a href="https://www.youtube.com/channel/UCfIvIVU0WrqVTJgVMgSdXCQ"  target="_blank" rel="noreferrer">
                    <Icon xmlns="http://www.w3.org/2000/svg" width="46.244" height="32.876" viewBox="0 0 46.244 32.876">
                        <path id="Subtração_1" data-name="Subtração 1" d="M-37.379,307.876c-.141,0-14.188-.011-17.677-.95a5.687,5.687,0,0,1-4-4.027c-.934-3.511-.945-10.886-.945-10.96s.011-7.45.945-10.961a5.684,5.684,0,0,1,4-4.026c3.489-.941,17.535-.952,17.677-.952s14.189.011,17.676.952a5.684,5.684,0,0,1,4,4.026c.934,3.511.945,10.887.945,10.961s-.011,7.449-.945,10.96a5.687,5.687,0,0,1-4,4.027C-23.19,307.865-37.237,307.876-37.379,307.876Zm-4.627-22.665v13.455l11.825-6.727-11.825-6.728Z" transform="translate(60.5 -275.5)" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
                    </Icon>
                </a>
            </IconWrapper>
        </SocialIcons>

        <LogoTQI src={logotqi} alt="TQI logo" onClick={handleClick}/>
        <p style={{color: '#A2A7AF'}}>DPO Responsável: Hudson Barbosa</p>
        <p style={{color: '#A2A7AF'}}>dpo@tqi.com.br</p>
        <p style={{color: '#A2A7AF', marginTop: 8}}>Copyright @ 2021 TQI CONSULTORIA E DESENVOLVIMENTO</p>
    </Container>
  );
}

export default Footer;
