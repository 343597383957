import styled from 'styled-components'

const handleSectionColor = color => {
    switch (color) {
      case "pink":
        return "transparent radial-gradient(closest-side at 50% 50%, #B0009F 0%, #5B0077 100%) 0% 0% no-repeat padding-box;";
      case "blue":
        return "transparent radial-gradient(closest-side at 50% 50%, #79AAEB 0%, #0F6FEE 100%) 0% 0% no-repeat padding-box;";
      case "white":
        return "#ffffff";
      case "gray":
        return "#dedede";
      default:
        return "#fff";
    }
};


export const Wrapper = styled.section `
    max-width: 1920px;
    margin: auto;
`

export const Section = styled.div `
    display: inline-block;
    background: ${({ color }) => handleSectionColor(color)};
    width: 100%;
`
export const Title = styled.h2 `
    color: ${props => props.color};
    font-size: 24px;
    text-align: center;
    max-width: 420px;
    margin: 40px auto 0 auto;

    @media (min-width: 768px) {
        font-size: 30px;
    }

    @media (min-width: 1024px) {
        margin: 60px auto 0 auto;
    }
`

export const GptwTitle = styled.h2 `
    color: ${props => props.color};
    font-size: 24px;
    text-align: center;
    max-width: 420px;
    margin: 40px auto 0 auto;

    @media (min-width: 768px) {
        margin: 30px auto;
        font-size: 30px;
    }

    @media (min-width: 1024px) {
        margin: 150px auto 0 auto;
    }
`

export const Subheader = styled.p `
    color: ${props => props.color};
    font-size: 16px;
    text-align: center;
    max-width: 300px;
    margin: 40px auto 0 auto;

    @media (min-width: 768px) {
        margin: 60px auto 0 auto;
        max-width: 420px;
        font-size: 20px;
    }
`

export const Text = styled.p `
    color: ${props => props.color};
    font-size: 16px;
    text-align: center;
    max-width: 300px;
    margin: 40px auto 0 auto;

    @media (min-width: 768px) {
        margin: 60px auto 0 auto;
        max-width: 1080px;
        font-size: 20px;
    }

`

export const Subheader2 = styled.p `
    color: ${props => props.color};
    font-size: 16px;
    text-align: center;
    max-width: 580px;
    margin: 60px auto 0 auto;

    @media (min-width: 1440px) {
        font-size: 20px;
        max-width: 1350px;
    }
`

export const SectionBlue = styled.section `
    background: transparent radial-gradient(closest-side at 50% 50%, #3886ED 0%, #1173EF 100%) 0% 0% no-repeat padding-box;
    width: 100%;
`

export const Button = styled.button `
    background-color: #0F6FEE;
    color: white;
    margin: 50px auto;
    display: block;
    width: 200px;
    height: 50px;
    border: 3px solid #FF00FF;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background-color: #0D67DE;
    }
`

export const DividerPink = styled.div `
    height: 2px;
    width: 80%;
    max-width: 934px;
    background-color: #FF00FF;
    margin: 60px auto 48px auto;
`

export const DividerBlue = styled.div `
    height: 2px;
    width: 80%;
    max-width: 934px;
    background-color: #91B8F3;
    margin: 60px auto 48px auto;
`

export const GptwSealWrapper = styled.div `
    width: 124px;
    margin: 0 auto;
    text-align: center;

    @media (min-width: 768px) {
        width: 700px;
        margin: 0 auto -100px auto;
        text-align: end;
    }

    @media (min-width: 1024px) {
        width: 860px;
        margin: 0 auto -200px auto;
    }

    @media (min-width: 1440px) {
        width: 1170px;
    }
`

export const GptwSeal = styled.img `
    width: 60px;
`