import React from 'react'
import { useHistory } from "react-router-dom"
import logotqi from '../../assets/logo-tqi.svg'
import { 
    HeroImage,
    HeroLogo
 } from './HeaderHero.styles'

function HeaderHero () {
    const history = useHistory();

    const handleClick = () => {
        history.push('/');
    }

    return <>
        <HeroImage>
            <HeroLogo src={logotqi} alt="TQI logo" onClick={handleClick}></HeroLogo>
        </HeroImage>
    </>
}

export default HeaderHero