import React from 'react'
import HeaderHero from '../../components/HeaderHero/HeaderHero'
import Footer from '../../components/Footer/Footer'
import Image from '../../assets/E-mkt-retorno.gif'
import ImgObrigado from '../../assets/obrigado.png'
import { 
    Section, 
    Wrapper,
    GridContainer,
    Text,
    SubText,
    GridContainerText,
    TextWrapper
} from './Thanks.styles'

function ThanksView () {
    return <Wrapper>
        <Section color="pink">
            <HeaderHero />
            <GridContainer>
                <TextWrapper>
                    <Text>O primeiro passo</Text>
                    <Text>foi dado. Agora,</Text>
                    <img className="textImage" src={ImgObrigado} alt=""/>
                </TextWrapper>
                <img className="gif" src={Image} alt=""/>
            </GridContainer>

            <GridContainerText>
                <SubText>Seu interesse em trabalhar conosco nos deixa orgulhosos demais. Estamos na torcida para que nossa conexão seja completa.</SubText>
                <SubText>Nos vemos em breve!</SubText>
            </GridContainerText>
        </Section>
        <Footer />
    </Wrapper>
}

export default ThanksView