import React from 'react'
import { 
    Container,
    Wrapper,
    Tab
 } from './Tabs.styles'

function Tabs ( { activeTab, handleTabChange } ) {
       

    return <Container>
        <Wrapper>
            <Tab 
                active={activeTab === 1} 
                onClick={() => handleTabChange(1)}
            >
                Seja mais saudável
            </Tab>
            <Tab 
                active={activeTab === 2} 
                onClick={() => handleTabChange(2)}
            >
                Evolua sua carreira
            </Tab>
            <Tab 
                active={activeTab === 3} 
                onClick={() => handleTabChange(3)}
            >
                Tenha mais tranquilidade
            </Tab>
            <Tab 
                active={activeTab === 4} 
                onClick={() => handleTabChange(4)}
            >
                Trabalhe feliz
            </Tab>
        </Wrapper>
    </Container>
}

export default Tabs