import React from 'react'
import { 
    Container, 
    ProjectsLogos, 
    Text,
    Logo,
    LogoContainer,
    Subheader
} from './Projects.styles'

import logoAzul from '../../assets/logo-azul.svg'
import logoLatam from '../../assets/logo-latam.svg'
import logoLopes from '../../assets/logo-lopes.svg'
import logoNextel from '../../assets/logo-nextel.svg'
import logoPagseguro from '../../assets/logo-pagseguro.svg'
import logoTribanco from '../../assets/logo-tribanco.svg'
import logoUol from '../../assets/logo-uol.svg'

function Projects () {

    return <Container>
        <Text><b>Conexão que desafia</b></Text>
        <Subheader>
        Venha atuar em projetos inovadores, com grandes players da nova economia
        </Subheader>
        <ProjectsLogos>
            <LogoContainer>
                <Logo src={logoPagseguro} alt="Vagas TQI - Pagseguro"/>
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoUol} alt="Vagas TQI - Uol" />
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoLatam} alt="Vagas TQI - Latam" />
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoAzul} alt="Vagas TQI - Azul" />
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoNextel} alt="Vagas TQI - Nextel" />
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoTribanco} alt="Vagas TQI - Tribanco" />
            </LogoContainer>
            <LogoContainer>
                <Logo src={logoLopes} alt="Vagas TQI - Lopes" />
            </LogoContainer>
        </ProjectsLogos>
    </Container>
}

export default Projects