import styled from 'styled-components'

export const Text = styled.p `
    font-size: 20px;
    color: white;
    text-align: center;
    max-width: 350px;

    &:first-child {
        margin-top:  40px;
    }

    @media (min-width: 768px) {
        margin-top:  0;
        text-align: left;
    }

    @media (min-width: 1440px) {
        font-size: 25px;
    }

`

export const Container = styled.div ``