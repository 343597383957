import styled from 'styled-components'

const handleSectionColor = color => {
    switch (color) {
      case "pink":
        return "transparent radial-gradient(closest-side at 50% 50%, #B0009F 0%, #5B0077 100%) 0% 0% no-repeat padding-box;";
      case "blue":
        return "transparent radial-gradient(closest-side at 50% 50%, #79AAEB 0%, #0F6FEE 100%) 0% 0% no-repeat padding-box;";
      case "white":
        return "#ffffff";
      case "gray":
        return "#dedede";
      default:
        return "#fff";
    }
};


export const Text = styled.p `
    font-size: 24px;
    color: white;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 35px;
    }

`

export const SubText = styled.p `
    font-size: 20px;
    color: white;
    padding: 20px;
    text-align: left;
`

export const Wrapper = styled.section `
    max-width: 1920px;
    margin: auto;
`

export const Section = styled.div `
    display: inline-block;
    background: ${({ color }) => handleSectionColor(color)};
    width: 100%;
    padding-bottom: 70px!important;
`

export const BackgroundImage = styled.img `
    width: 100%;
    margin-top: -225px;
    opacity: 0.3;

    @media (min-width: 768px) {
        margin-top: -425px;
    }

    @media (min-width: 1280px) {
        margin-top: -570px;
    }

    @media (min-width: 1440px) {
        margin-top: -496px;
    }

    @media (min-width: 1700px) {
        margin-top: -600px;
    }

    @media (min-width: 1920px) {
        margin-top: -662px;
    }
`

export const TextWrapper = styled.div `
    text-align: center;
    
    @media (min-width: 1024px) {
        margin-top: 70px;
    }

`

export const GridContainer = styled.div `
    width: 300px;
    margin: 50px auto 20px auto;

    img.textImage {
        max-width: 300px;
    }

    img.gif {
        margin: 30px auto 0 auto;
        display: block;
        width: 220px;
    }

    @media (min-width: 768px) {
        width: 730px;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-around;
    }
`

export const GridContainerText = styled.div `
    width: 300px;
    margin: 30px auto 0 auto;

    @media (min-width: 768px) {
        width: 700px;
    }
    
`

