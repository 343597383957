import styled from 'styled-components'

export const Container = styled.div `
    max-width: 1249px;
    margin: auto;
`

export const Text = styled.p `
    font-size: 24px;
    color: white;
    max-width: 648px;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 30px;
    }
`
export const Subheader = styled.p `
    color: #FFF;
    font-size: 16px;
    text-align: center;
    max-width: 455px;
    margin: 60px auto 0 auto;

    @media (min-width: 768px) {
        font-size: 20px;
    }
`

export const ProjectsLogos = styled.div `
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
    padding-bottom: 100px;
    justify-content: center;
`

export const LogoContainer = styled.div `
    width: 270px;
    height: 120px;
    text-align: center;
`

export const Logo = styled.img `
    height: 40px;
    width: 100%;
`