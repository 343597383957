import styled from 'styled-components'

export const Container = styled.div `
    margin: auto;
`

export const BenefitsContainer = styled.div `
    margin: 50px auto 50px auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;

    @media (min-width: 768px) {
        max-width: 700px;
    }

    @media (min-width: 1024px) {
        max-width: 900px;
    }

    @media (min-width: 1280px) {
        max-width: 1110px;
    }

    @media (min-width: 1440px) {
        max-width: 1300px;
    }
`

export const BenefitContainer = styled.div `
    width: 210px;
    height: 200px;
    border: 2px solid #91B8F3;
    border-radius: 20px;
    text-align: center;
    margin: 10px auto;
    padding: 50px 5px;
    cursor: pointer;

    &:hover {
        border: 2px solid #133D7E;
    }

    @media (min-width: 768px) {
        width: 242px;
        height: 216px;
    }
`

export const BenefitContainerHidden = styled.div `
    width: 242px;
    border: 2px solid #91B8F3;
    border-radius: 20px;
    margin: 10px auto;
    visibility: hidden;
`

export const BenefitIcon = styled.img `
    width: 50px;
    height: 50px;
`

export const BenefitDescription = styled.p `
    color: #91B8F3;
    font-size: 14px;
    margin-top: 15px;

    @media (min-width: 768px) {
        font-size: 16px;
    }
`