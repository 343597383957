import styled from 'styled-components'

export const Text = styled.p `
    font-size: 12px;
    color: white;
    text-align: center;
    max-width: 300px;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 30px;
        margin-left: 20px;
        max-width: 465px;
        text-align: left;
    }

`
export const Name = styled.p `
    font-size: 12px;
    color: white;
    text-align: center;
    max-width: 400px;
    margin: 10px 0;
    font-weight: bold;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }

`
export const Job = styled.p `
    font-size: 10px;
    color: white;
    text-align: center;
    max-width: 400px;
    margin: 10px 0;

    @media (min-width: 768px) {
        font-size: 14px;
    }

`

export const Container = styled.div `
    padding-bottom: 50px;

    @media (min-width: 768px) {
        padding-bottom: 80px;
        margin-top: 30px
    }

`