import styled from 'styled-components'

const handleSectionColor = color => {
    switch (color) {
      case "pink":
        return "transparent radial-gradient(closest-side at 50% 50%, #B0009F 0%, #5B0077 100%) 0% 0% no-repeat padding-box;";
      case "blue":
        return "transparent radial-gradient(closest-side at 50% 50%, #79AAEB 0%, #0F6FEE 100%) 0% 0% no-repeat padding-box;";
      case "white":
        return "#ffffff";
      case "gray":
        return "#dedede";
      default:
        return "#fff";
    }
};


export const Wrapper = styled.section `
    max-width: 1920px;
    margin: auto;
`

export const Section = styled.div `
    display: inline-block;
    background: ${({ color }) => handleSectionColor(color)};
    width: 100%;
`

export const BackgroundImage = styled.img `
    width: 100%;
    margin-top: -225px;
    opacity: 0.3;

    @media (min-width: 768px) {
        margin-top: -425px;
    }

    @media (min-width: 1280px) {
        margin-top: -570px;
    }

    @media (min-width: 1440px) {
        margin-top: -496px;
    }

    @media (min-width: 1700px) {
        margin-top: -600px;
    }

    @media (min-width: 1920px) {
        margin-top: -662px;
    }
`

export const DividerBlue = styled.div `
    height: 2px;
    width: 80%;
    max-width: 700px;
    background-color: #91B8F3;
    margin: 60px auto 48px auto;

    @media (min-width: 1440px) {
        max-width: 1024px;
    }
`

export const Title = styled.h2 `
    font-size: 24px;
    color: #154082;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 50px auto;

    @media (min-width: 768px) {
        font-size: 26px;
        max-width: 700px;
    }

    @media (min-width: 1440px) {
        font-size: 30px;
        max-width: 1350px;
    }
`

export const Text = styled.p `
    font-size: 16px;
    color: #ADAFB2;
    max-width: 300px;
    margin: 0 auto 0 auto;
    text-align: justify;

    ul {
        text-align: left;
    }

    @media (min-width: 768px) {
        font-size: 20px;
        max-width: 700px;
    }

    @media (min-width: 1440px) {
        max-width: 1024px;
    }
`