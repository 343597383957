import styled from 'styled-components'

export const Container = styled.div `
    margin: 50px auto 0 auto;
    overflow-x: auto;
    width: 300px;

    @media (min-width: 768px) {
        overflow-x: unset;
        width: 640px;
    }

    @media (min-width: 1024px) {
        margin: 80px auto 0 auto;
        width: 840px;
    }

    @media (min-width: 1280px) {
        width: 1070px;
    }

    @media (min-width: 1440px) {
        width: 1270px;
    }
`

export const Wrapper = styled.div `
    display: flex;
    justify-content: space-between;
    width: 550px;

    @media (min-width: 768px) {
        width: 100%;
    }
`

export const Tab = styled.div `
    font-size: 14px;
    padding-bottom: 10px;
    cursor: pointer;
    ${props => props.active ? 'color: #154082;' : 'color: #B4B5B5;'}
    ${props => props.active ? 'border-bottom: 2px solid #91B8F3;' : 'border-bottom: 2px solid #B4B5B5;'}
    ${props => props.active ? 'font-weight: bold;' : ''}

    @media (min-width: 768px) {
        font-size: 16px;
    }

    @media (min-width: 1024px) {
        min-width: 178px;
        text-align: center;
    }

    @media (min-width: 1440px) {
        min-width: 222px;
        font-size: 20px;
    }
`