import React from 'react'
import { Container } from './Carrousel.styles'
import { Swiper, SwiperSlide } from "swiper/react"
import foto1 from '../../assets/fotos-tqi/foto1.jpg'
import foto3 from '../../assets/fotos-tqi/foto3.jpg'
import foto4 from '../../assets/fotos-tqi/foto4.jpg'
import foto5 from '../../assets/fotos-tqi/foto5.jpg'
import foto6 from '../../assets/fotos-tqi/foto6.jpg'
import foto7 from '../../assets/fotos-tqi/foto7.jpg'
import foto8 from '../../assets/fotos-tqi/foto8.jpg'
import foto9 from '../../assets/fotos-tqi/foto9.jpg'
import foto10 from '../../assets/fotos-tqi/foto10.jpg'

// import Swiper core and required modules
import SwiperCore, {
    Autoplay,Navigation
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Autoplay,Navigation]);
  
const Carrousel2 = () => {
    return (
    <Container>
         <Swiper
            loop={true} 
            loopFillGroupWithBlank={true}
            navigation={true} 
            className="mySwiper"
            breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 2,
                },
                1280: {
                  slidesPerView: 3,
                }
            }}
            autoplay={{
                "delay": 7000,
                "disableOnInteraction": false
              }}
         >
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto1} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto3} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto4} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto5} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto6} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto7} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto8} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto9} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <img className="carrousel2Img" src={foto10} alt="TQI - vagas tecnologia"/>
                </div>
            </SwiperSlide>
        </Swiper>
    </Container>
    ) 
}

export default Carrousel2