import React from 'react'
import { Container, Text } from './Carrousel.styles'
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";
import banner1 from '../../assets/banner1.svg'
import banner2 from '../../assets/banner2.svg'

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation,Keyboard
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Autoplay, Navigation,Keyboard]);

const Carrousel = () => {

    return (
    <Container>
        <Swiper 
            navigation={true} 
            cssMode={true}
            keyboard={true}
            loop={true}
            className="mySwiper" 
            autoplay={{
                "delay": 7000,
                "disableOnInteraction": false
            }}
        >
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img className="carrousel1Img" src={banner1} alt="TQI - vagas tecnologia" />
                    </div>
                    <div>
                        <Text>Seja parte de um universo colaborativo, que conecta os propósitos de um time extraordinário, em uma jornada incrível.</Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img className="carrousel1Img" src={banner2} alt="TQI - vagas tecnologia"/>
                    </div>
                    <div>
                        <Text><b>Conexão de pessoas</b></Text>
                        <Text style={{marginLeft: 20}}>negócios</Text>
                        <Text style={{marginLeft: 30}}>conhecimento</Text>
                        <Text style={{marginLeft: 40}}>propósitos</Text>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </Container>
    ) 
}

export default Carrousel