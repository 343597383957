import styled from 'styled-components'

export const Container = styled.div `

`

export const Wrapper = styled.div `
    
`

export const Title = styled.h2 `
    color: white;
    font-size: 24px;
    text-align: center;
    margin: 70px auto 50px auto;

    @media (min-width: 768px) {
        font-size: 30px;
    }
`

export const EventsContainer = styled.div `
    max-width: 750px;
    margin: 70px auto 0 auto; 

    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: 1280px) {
        max-width: 1070px;
    }

    @media (min-width: 1440px) {
        max-width: 1300px;
    }
`

export const EventContainer = styled.div `
    width: 254px;
    height: 200px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        height: 245px;
    }
`

export const EventIcon = styled.img `
    display: block;
    margin: auto;
    width: 80px;
    height: 80px;
`

export const EventDescription = styled.div `
    color: white;
    font-size: 14px;
    text-align: center;
    margin: 20px auto 0 auto;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }

    @media (min-width: 1024px) {
        margin: 40px auto 0 auto;
    }
`