import React, { useState } from 'react'
import Tabs from '../Tabs/Tabs'
import { 
    Container,
    BenefitsContainer,
    BenefitContainer,
    BenefitIcon,
    BenefitDescription,
    BenefitContainerHidden
 } from './Benefits.styles'
 
import icon1 from '../../assets/benefits/C1-01.svg'
import icon2 from '../../assets/benefits/C1-02.svg'
import icon3 from '../../assets/benefits/C1-03.svg'
import icon4 from '../../assets/benefits/C1-04.svg'
import icon5 from '../../assets/benefits/C1-05.svg'
import icon6 from '../../assets/benefits/C1-06.svg'
import icon7 from '../../assets/benefits/C1-07.svg'
import icon8 from '../../assets/benefits/C1-08.svg'

import icon9 from '../../assets/benefits/C2-01.svg'
import icon10 from '../../assets/benefits/C2-02.svg'
import icon11 from '../../assets/benefits/C2-03.svg'
import icon12 from '../../assets/benefits/C2-04.svg'
import icon13 from '../../assets/benefits/C2-05.svg'
import icon14 from '../../assets/benefits/C2-06.svg'
import icon15 from '../../assets/benefits/C2-07.svg'

import icon16 from '../../assets/benefits/C3-01.svg'
import icon17 from '../../assets/benefits/C3-02.svg'
import icon18 from '../../assets/benefits/C3-03.svg'
import icon19 from '../../assets/benefits/C3-04.svg'
import icon20 from '../../assets/benefits/C3-05.svg'

import icon21 from '../../assets/benefits/C4-01.svg'
import icon22 from '../../assets/benefits/C4-02.svg'
import icon23 from '../../assets/benefits/C4-03.svg'
import icon24 from '../../assets/benefits/C4-04.svg'
import icon25 from '../../assets/benefits/C4-05.svg'
import icon26 from '../../assets/benefits/C4-06.svg'
import icon27 from '../../assets/benefits/C4-07.svg'

function Benefits () {

    const [activeTab, setActiveTab] = useState(1); 

    function handleTabChange(tab) {
        setActiveTab(tab);
    }

    return <Container>
        <Tabs activeTab={activeTab} handleTabChange={handleTabChange} />

        {
            activeTab === 1 
            ?<BenefitsContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon1} />
                    <BenefitDescription>Convênio Médico</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon2} />
                    <BenefitDescription>Convênio Odontológico</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon3} />
                    <BenefitDescription>Seguro de Vida</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon4} />
                    <BenefitDescription>Gympass pra você e mais 3 dependentes</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon5} />
                    <BenefitDescription>Mimos em datas especiais</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon6} />
                    <BenefitDescription>Talks internos sobre qualidade de vida e assuntos variados</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon7} />
                    <BenefitDescription>Ambiente saudável</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon8} />
                    <BenefitDescription>Equilíbrio entre vida pessoal e profissional</BenefitDescription>
                </BenefitContainer>
                <BenefitContainerHidden />
                <BenefitContainerHidden />
            </BenefitsContainer>
            : ''
        }
        

        {
            activeTab === 2
            ?<BenefitsContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon9} />
                    <BenefitDescription>Parceria com escolas de idiomas</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon10} />
                    <BenefitDescription>Desconto em cursos de graduação e pós-graduação</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon11} />
                    <BenefitDescription>Iniciativas de aprendizado e troca de conhecimento</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon12} />
                    <BenefitDescription>Meetups e talks técnicos</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon13} />
                    <BenefitDescription>Licenças para acesso a plataformas de cursos online</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon14} />
                    <BenefitDescription>Feedbacks constantes</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon15} />
                    <BenefitDescription>Atuação/aprendizado nas tecnologias mais atuais do mercado</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer style={{visibility: 'hidden'}}/>
                <BenefitContainer style={{visibility: 'hidden'}}/>
                <BenefitContainer style={{visibility: 'hidden'}}/>
            </BenefitsContainer>
            : ''
        }

        {
            activeTab === 3
            ?<BenefitsContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon16} />
                    <BenefitDescription>Vale refeição e alimentação</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon17} />
                    <BenefitDescription>Auxílio Home-office</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon18} />
                    <BenefitDescription>Auxílio creche</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon19} />
                    <BenefitDescription>Participação nos Lucros e Resultados</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon20} />
                    <BenefitDescription>Premiação por indicação</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer style={{visibility: 'hidden'}}/>
            </BenefitsContainer>
            : ''
        }

        {
            activeTab === 4
            ?<BenefitsContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon21} />
                    <BenefitDescription>Ambiente descontraído</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon22} />
                    <BenefitDescription>Happy hours frequentes</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon23} />
                    <BenefitDescription>Noites de jogos</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon24} />
                    <BenefitDescription>Ações comemorativas</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon25} />
                    <BenefitDescription>Interação constante entre as equipes</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon26} />
                    <BenefitDescription>Flexibilidade e Autonomia</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer>
                    <BenefitIcon src={icon27} />
                    <BenefitDescription>Confiança e ambiente seguro</BenefitDescription>
                </BenefitContainer>
                <BenefitContainer style={{visibility: 'hidden'}}/>
                <BenefitContainer style={{visibility: 'hidden'}}/>
                <BenefitContainer style={{visibility: 'hidden'}}/>
            </BenefitsContainer>
            : ''
        }
    </Container>
}

export default Benefits