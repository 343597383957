import React from 'react'
import culture1 from '../../assets/cultura-01.svg'
import culture2 from '../../assets/cultura-02.svg'
import culture3 from '../../assets/cultura-03.svg'
import { 
    Container,
    Wrapper,
    Title,
    EventsContainer,
    EventContainer,
    EventIcon,
    EventDescription
 } from './Events.styles'

function Events () {

    return <Container>
        <Wrapper>
            <Title>Conexão que equilibra</Title>
            <EventsContainer>
                <EventContainer>
                    <EventIcon src={culture1} />
                    <EventDescription>
                        Cultura colaborativa: trocamos experiências e aprendemos sempre
                    </EventDescription>
                </EventContainer>
                <EventContainer>
                    <EventIcon src={culture2} />
                    <EventDescription>
                        Ambiente seguro e acolhedor: temos liberdade para sermos nós mesmos
                    </EventDescription>
                </EventContainer>
                <EventContainer>
                    <EventIcon src={culture3} />
                    <EventDescription>
                        Somos valorizados por contribuições e conquistas
                    </EventDescription>
                </EventContainer>
                <EventContainer>
                    <EventIcon src={culture3} />
                    <EventDescription>
                        Trabalhamos com equilíbrio e autonomia
                    </EventDescription>
                </EventContainer>
            </EventsContainer>
        </Wrapper>
    </Container>
}

export default Events