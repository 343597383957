import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import Checkbox from '../../components/Checkbox/Checkbox'
import { 
    Container,
    Input,
    InputMask,
    Button,
    Text,
    InputFile,
    FileUploader,
    ErrorMessage,
    ReactLoadingComponent,
    FileIcon
} from './Form.styles'
import { url } from '../../service.json'
import fileIcon from '../../assets/file.svg'
import jobsList from '../../jobsList.json'
import jobsListBank from '../../jobsListBank.json'

const inputErrorInitialState = {
    name: false,
    email: false,
    cellphone: false
}

export default function Form ( ) {
    const history = useHistory();
    const [user, setUser] = useState({
        name: '',
        email: '',
        cellphone: '',
        linkedin: ''
    })
    const [fileName, setFileName] = useState("Anexar curriculo");
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [checked, setChecked] = useState(1);
    const [inputError, setInputError] = useState(inputErrorInitialState);
    const path = window.location.pathname.split('-');
    const id = path[path.length - 1 ];
    const vaga = jobsList.filter((job) => job.id === id )[0] || jobsListBank.filter((job) => job.id === id )[0];
    const form = useRef(null);

    const handleUserChange = e => {
        const newUser = { ...user, [e.target.id]: e.target.value };
        setUser(newUser);
    }
    
    const onFileChange = e => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    const isDataValid = () => {
        var error = inputError;
        if(user.name.length === 0) {
            error = {...error, name: true}
        } else {
            error = {...error, name: false}
        }
        if(user.email.length === 0) {
            error = {...error, email: true}
        } else {
            error = {...error, email: false}
        }
        if(user.cellphone.length === 0) {
            error = {...error, cellphone: true}
        } else {
            error = {...error, cellphone: false}
        }

        setInputError(error);
        
        if(user.name.length === 0 ||
            user.email.length === 0 ||
            user.cellphone.length === 0) {
            return false
        } else {
            return true
        }
    }
    
    const submit = e => {
        e.preventDefault()
        if(isDataValid()) {
            setErrorMessage('');
            setLoading(1);
            const formData = new FormData(form.current);
            formData.append('name', user.name);
            formData.append('email', user.email);
            formData.append('occupation ', vaga['id-actuation-area']);
            formData.append('opening ', vaga.id);
            formData.append('cellphone', user.cellphone);
            formData.append('linkedin', user.linkedin);
            formData.append('curriculum', file);    

            fetch(url, { method: 'POST', body: formData })
                .then((response) => response.json())
                .then((result) => {
                    setLoading(0);

                    if(result.code === "0000" || result.status === 500) {
                        setErrorMessage(result.message);
                    } else {
                        history.push('/obrigado');
                    }
                })
                .catch((error) => {
                    setLoading(0);
                    setErrorMessage(error.message);
                });
        } else {
            setErrorMessage("Preencha todos os campos obrigatórios. *");
        }
    }

    function handleToggle () {
        setChecked(!checked);
    }

    useEffect(() => {
        console.log(inputError)
    }, [inputError]);

    return <Container>
        <form ref={form} onSubmit={submit}>
            <Input 
                placeholder="Nome completo*" 
                id="name" 
                onChange={e => handleUserChange(e)}
                error={inputError.name}
            />
            <Input 
                placeholder="E-mail*" 
                id="email" 
                type="email" 
                onChange={e => handleUserChange(e)}
                error={inputError.email}
            />
            <InputMask
                placeholder="Telefone*" 
                id="cellphone" 
                type="text" 
                mask="(99) 99999-9999"
                onChange={e => handleUserChange(e)}
                error={inputError.cellphone}
            />
            <Input 
                placeholder="Link do LinkedIn" 
                id="linkedin" 
                onChange={e => handleUserChange(e)} 
            />
            <FileUploader htmlFor="file" >
                <FileIcon src={fileIcon} alt="" />
                {fileName}
            </FileUploader>
            <InputFile type="file" name="file" id="file" onChange={onFileChange} />
            <ErrorMessage>{errorMessage}</ErrorMessage>

            <Text>
                Informamos que os seus dados pessoas inseridos neste campo, a partir deste momento, 
                são utilizados exclusivamente para retornar à solicitação feita pelo titular de 
                dados nos termos do artigo 7º, V, da Let nº 13.709/18 (LGPD).
            </Text>
            <Checkbox
                value={checked} 
                title="Li e aceito os termos.*" 
                onClick={() => handleToggle()}
            />
            <Button type="submit" disabled={!checked}>Finalizar candidatura</Button>
            <ReactLoadingComponent type="spinningBubbles" color="#0F6FEE" loading={loading} />
        </form>
    </Container>
}