import React, { useState } from "react";
import { 
  Container, 
  TextContainer, 
  DivRow, 
  DivColumn, 
  Input, 
  Select, 
  Option,
  JobsWrapper,
  DividerPink,
  Title
} from "./JobList.style";
import jobsList from '../../jobsList.json';
import jobsListBank from '../../jobsListBank.json';

export default function JobList() {

  const [jobs, setJobs] = useState(jobsList);
  const [jobsBank, setJobsBank] = useState(jobsListBank);
  const [filters, setFilters] = useState({
    name: '',
    local: '',
    area: ''
  })

  const onJobClick = (item) => {
    window.open(`/vaga/${item.uri}`,"_self");
    localStorage.setItem('vaga', JSON.stringify(item));
  }

  const onFilterChange = e => {
    const newFilters = { ...filters, [e.target.id]: e.target.value };
    setFilters(newFilters);
    const newJobs = jobsList.filter(job => {
        return (job.name.toLowerCase().includes(newFilters.name.toLowerCase()) || newFilters.name === "")
        && (job.local === newFilters.local || newFilters.local === "") 
        && (job['id-actuation-area'] === parseInt(newFilters.area) || newFilters.area === "9" || newFilters.area === "")
    });
    const newJobsBank = jobsListBank.filter(job => {
      return (job.name.toLowerCase().includes(newFilters.name.toLowerCase()) || newFilters.name === "")
      && (job.local === newFilters.local || newFilters.local === "") 
      && (job['id-actuation-area'] === parseInt(newFilters.area) || newFilters.area === "9" || newFilters.area === "")
    });
    setJobs(newJobs);
    setJobsBank(newJobsBank);
  }

  return (
    <Container>
      <DivRow>
          <DivColumn>
            <Input type="text" id="name" onChange={onFilterChange} value={filters.name} placeholder="Pesquisar..."/>
          </DivColumn>
          
          <DivColumn>
            <Select id="local" name="local" onChange={onFilterChange} value={filters.local}>
              <Option value="" disabled>Local de trabalho</Option>
              <Option value="">Todos locais</Option>
              <Option value="100% remoto">100% Remoto</Option>
              <Option value="São Paulo">São Paulo</Option>
            </Select>
          </DivColumn>
          
          <DivColumn>
            <Select id="area" name="area" onChange={onFilterChange} value={filters.area}>
              <Option value="" disabled>Selecionar por área</Option>
              {/* <Option value="">Todas áreas</Option>
              <Option value="1">Agile</Option>
              <Option value="2">Android</Option>
              <Option value="3">Backend</Option>
              <Option value="4">Frontend</Option>
              <Option value="5">IOS</Option>
              <Option value="6">QA</Option>
              <Option value="7">SRE/Devops</Option>
              <Option value="8">Outros</Option> */}
              <Option value="9">Eng. de Software</Option>
            </Select>
          </DivColumn>
      </DivRow>
      <br/><br/><br/>

      <JobsWrapper>
        <DividerPink></DividerPink>

        <Title>Banco de talentos</Title>      
        {jobsBank.length
          ? jobsBank.map((item, index) => {
              return <TextContainer onClick={event => onJobClick(item)} key={index} color="#FFF">{item.name} - ({item.local})</TextContainer>
            })
          : <TextContainer>Não encontramos nenhuma vaga. :(</TextContainer>
        }
        <br/>

        <DividerPink></DividerPink>

        <br/>
        <Title>Engenharia de Software</Title>   
        {jobs.length
          ? jobs.map((item, index) => {
              return <TextContainer onClick={event => onJobClick(item)} key={index} color="#FFF">{item.name} - ({item.local})</TextContainer>
            })
          : <TextContainer>Não encontramos nenhuma vaga. :(</TextContainer>
        }
        <br/><br/><br/><br/>
      </JobsWrapper>
    </Container>
  );
}
