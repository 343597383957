import React, { useRef } from 'react'
import HeaderHero from '../../components/HeaderHero/HeaderHero'
import Carrousel from '../../components/Carrousel/Carrousel'
import Carrousel2 from '../../components/Carrousel2/Carrousel'
import Carrousel3 from '../../components/Carrousel3/Carrousel'
import Projects from '../../components/Projects/Projects'
import Footer from '../../components/Footer/Footer'
import FormListJobs from '../../components/JobList/JobList'
import Events from '../../components/Events/Events'
import Benefits from '../../components/Benefits/Benefits'
import gptw from '../../assets/selo-gptw.svg'
import {
    Section, 
    Wrapper,
    Button,
    DividerPink,
    DividerBlue,
    Title,
    GptwTitle,
    Subheader,
    GptwSeal,
    GptwSealWrapper,
    Text
} from './Home.styles'

function HomeView () {
    const scrollRef = useRef();

    function scrollToRef () {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return <Wrapper>
        <Section color="pink">
            <HeaderHero />
            <Carrousel />
            <Button type="button" onClick={scrollToRef} title="Vem pra TQI">Vem pra TQI</Button>
            <DividerPink></DividerPink>
            <Projects />
        </Section>
        
        <Section color="blue">
            <GptwSealWrapper>
                <GptwSeal src={gptw} />
            </GptwSealWrapper>
            <GptwTitle color="#FFF"><b>Conexão que evolui</b></GptwTitle>
            <Subheader color="#FFF">
                Somos uma empresa de pessoas experientes, adaptáveis e com 
                busca incessante por conhecimento
            </Subheader>
            <Carrousel2/>
            <DividerBlue></DividerBlue>
            <Events />
            <DividerBlue></DividerBlue>
            <Title color="#FFF"><b>Conexão que inspira</b></Title>
            <Carrousel3/>
            <DividerBlue></DividerBlue>
            <Title color="#FFF"><b>Conexão que inclui</b></Title>
            <Text color="#FFF">
            Esperamos que você seja você. Aqui, nos comprometemos em construir 
            um ambiente de trabalho que conecte pessoas diversas. Por isso, 
            nosso processo de seleção considera todas as pessoas, sem distinção 
            de raça, etnia, religião, identidade de gênero, orientação sexual, 
            nacionalidade, deficiência ou idade.
            </Text>
            <br /><br /><br /><br /><br />
        </Section>
        
        <Section color="white">
            <Title color="#154082"><b>Conexão que valoriza</b></Title>
            <Benefits />
        </Section>

        <Section ref={scrollRef} color="pink">
            <Title color="#FFF"><b>#vempratqi</b></Title>
            <Subheader color="#FFF">Confira nossas vagas!</Subheader>
            <FormListJobs></FormListJobs>
        </Section>

        <Footer />
    </Wrapper>
}

export default HomeView