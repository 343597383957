import styled from 'styled-components'
import section1bg from '../../assets/header-dots.svg'

export const HeroImage = styled.div `
    background-image: url(${section1bg});
    height: 180px;
    background-position: right -30px bottom 100px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    margin-bottom: -50px;

    @media (min-width: 768px) {
        height: 260px;
        background-position: right -20px bottom 100px;
    }

    @media (min-width: 1024px) {
        height: 300px;
    }
`

export const HeroLogo = styled.img `
    width: 160px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;

    @media (min-width: 768px) {
        top: 35%;
    }
`