import styled from 'styled-components'

export const Container = styled.div `
    margin: auto;
`

export const Wrapper = styled.div `
    display: block;

    @media (min-width: 1024px) {
        display: flex;
        margin-top: 150px;
        width: 900px;
        margin: 0 auto;
    }


    @media (min-width: 1440px) {
        width: 1024px;
    }
`

export const JobDescription = styled.div `
    width: 300px;
    margin: 30px auto;

    @media (min-width: 768px) {
        width: 714px;
    }

`

export const JobName = styled.h1 `
    font-size: 24px;
    color: white;

    @media (min-width: 768px) {
        font-size: 35px;
    }
`

export const JobLocal = styled.div `
    font-size: 18px;
    color: white;
    display: flex;
    margin-top: 20px;

    @media (min-width: 768px) {
        font-size: 20px;
    }
`

export const JobLocalText = styled.p `
    margin-top: 5px;
`

export const PinIcon = styled.img `
    display: inline;
    margin-right: 15px;
    width: 15px;
    
    @media (min-width: 768px) {
        width: 24px;
    }
`

export const Button = styled.button `
    background-color: #0F6FEE;
    color: white;
    margin: 50px auto;
    display: block;
    width: 300px;
    height: 50px;
    border: 3px solid #FF00FF;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    
    &:hover {
        background-color: #0D67DE;
    }

    @media (min-width: 1440px) {
        width: 350px;
        height: 70px;
        border: 6px solid #FF00FF;
        border-radius: 20px;
        font-size: 24px;
    }
`

export const ShareText = styled.p `
    font-size: 14px;
    color: #FF00FF;
    margin: 0 auto;
    width: 205px;

    @media (min-width: 768px) {
        width: 184px;
    }
`