import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { 
    Container, 
    Text, 
    Name, 
    Job 
} from './Carrousel.styles'
import depo1 from '../../assets/depos/depo1.png'
import depo2 from '../../assets/depos/depo2.png'
import depo3 from '../../assets/depos/depo3.png'
import depo4 from '../../assets/depos/depo4.png'
import depo5 from '../../assets/depos/depo5.png'
import depo6 from '../../assets/depos/depo6.png'
import depo7 from '../../assets/depos/depo7.png'
import depo8 from '../../assets/depos/depo8.png'

// Import Swiper styles
// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation,Keyboard
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Autoplay, Navigation,Keyboard]);

const Carrousel = () => {

    return (
    <Container>
        <Swiper 
            navigation={true}
            loop={true}
            keyboard={true} 
            className="mySwiper"
            autoplay={{
                "delay": 7000,
                "disableOnInteraction": false
              }}
        >
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo1} className="carrousel3Img " alt="TQI - vagas tecnologia"/>
                        <Name>Cláudia Lara</Name>
                        <Job>Tech Recruiter</Job>
                    </div>
                    <div>
                        <Text>
                            Fazer parte da TQI tem sido uma experiência única! Aqui, respiramos inovação. Com ambiente dinâmico e colaborativo, somos desafiados todos os dias. Ter voz ativa, autonomia e liberdade para criar faz toda diferença. Além de acreditar na cultura e nos valores da empresa, tenho muita admiração e orgulho das pessoas que trabalham aqui. O sentimento de pertencer é o que alimenta ainda mais a minha paixão pela TQI. 
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo2} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Kéllyson Alves</Name>
                        <Job>UX/UI Designer</Job>
                    </div>
                    <div>
                        <Text>
                            A TQI é um lugar respeitoso, que proporciona sua evolução, cheia de pessoas dispostas a ajudar e o principal: um lugar que me valoriza como colaborador e, especialmente, como pessoa. Trabalhar na TQI foi algo que mudou minha vida e sou extremamente grato por fazer parte do time! 
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo3} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Luís Teodoro Junior</Name>
                        <Job>Engenheiro iOS</Job>
                    </div>
                    <div>
                        <Text>
                            A TQI vem me surpreendendo positivamente a cada dia, uma empresa que valoriza e respeita os colaboradores, praticando uma cultura ágil e colaborativa! Uma empresa que acredita na liberdade e diversidade. Como dizem por aí: “Meu escritório é na praia, eu tô sempre na área...”. Tenho muito orgulho de fazer parte do crescimento e evolução TQI, já são muitos anos de parceria e muitos projetos. #vemsertqi 
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo4} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Marlon Lopes</Name>
                        <Job>Agile Master</Job>
                    </div>
                    <div>
                        <Text>
                            Tenho orgulho de trabalhar na TQI porque me identifico muito com os valores da empresa. A TQI é uma empresa de gente e eu fico muito feliz em fazer parte das conquistas. #eusoutqi
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo5} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Ricardo Albenes</Name>
                        <Job>Coordenador de TI</Job>
                    </div>
                    <div>
                        <Text>
                            É muito bom estar em um lugar alinhado com os seus valores e que realmente pensa nas pessoas, ou seja, que não fica só no discurso. Assim é a TQI, uma empresa que nos motiva e nos incentiva a evoluir enquanto pessoa e profissional. E mesmo diante dos cenários desafiadores do dia a dia, existe uma leveza e um cuidado com o ser humano que faz toda a diferença. Sem dúvida é um lugar onde pretendo estar muitos anos e sinto-me desafiado e realizado nesta empresa. É uma alegria ser TQI!
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo6} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Salger Oliveira</Name>
                        <Job>Coordenador de TI</Job>
                    </div>
                    <div>
                        <Text>
                            Na TQI tenho espaço para aprender com os erros e evoluir através da observação e medição, sempre sendo instigado a ir além do achismo. O ambiente colaborativo da empresa proporciona discussões saudáveis, que me tornam uma pessoa melhor, não apenas em assuntos técnicos, mas na vida. Muitas vezes saio de reuniões do trabalho com reflexões que me levam a repensar as minhas ações com a minha família e amigos. Eu me sinto feliz por tudo que aprendi na TQI e por ser parte desse processo tão humano de transformação.
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo7} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Tharlesson Souza</Name>
                        <Job>Engenheiro SRE</Job>
                    </div>
                    <div>
                        <Text>
                            Trabalhar na TQI nos proporciona uma experiência única, com desafios e colaboração, com uma gestão ímpar que inspira os colaboradores. Por aqui encontramos diversas oportunidades para alcançar nossos objetivos e metas profissionais. Trabalhando sempre com as mais recentes tecnologias onde estamos sempre aprendendo, sempre alcançando resultados.
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="grid-container">
                    <div>
                        <img src={depo8} className="carrousel3Img" alt="TQI - vagas tecnologia"/>
                        <Name>Wallyson Ferreira</Name>
                        <Job>Coordenador de Infraestrutura</Job>
                    </div>
                    <div>
                        <Text>
                            Há quase 1 ano a TQI me abraçou. A cor azul começou a fazer parte do meu dia a dia. Eu falava que nunca mais trabalharia em uma empresa privada novamente, mas nunca me encontrei tanto como na TQI e pude também encontrar pessoas incríveis, além de uma filosofia de trabalho coerente e encantadora.
                        </Text>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </Container>
    ) 
}

export default Carrousel