import styled from 'styled-components'
import ReactLoading from "react-loading";
import InputMaskComponent from 'react-input-mask';

export const Container = styled.div `
    margin: 50px auto 0 auto;
    width: 300px;
    text-align: center;
    
    @media (min-width: 768px) {
        width: 384px;
    }

    @media (min-width: 1024px) {
        width: 800px;
    }

`

export const FileIcon = styled.img`
    float: left;
    height: 18px;
    margin: -1px 4px 0px -10px;

    @media (min-width: 768px) {
        height: 22px;
        margin: -4px 6px 0px -10px;
    }

    @media (min-width: 1024px) {
        margin: -4px 6px 0px -22px;
    }
`

export const FileUploader = styled.label `
    display: block;
    width: 300px;
    height: 33px;
    margin: 0 auto 20px auto;
    padding: 8px 20px;
    background-color: #91B8F3;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: #023867;
    text-align: left;
    font-size: 14px;
    
    @media (min-width: 768px) {
        width: 384px;
        height: 66px;
        padding: 24px 20px;
        border-radius: 10px;
    }

    @media (min-width: 1024px) {
        width: 800px;
        padding: 24px 35px;
    }
`

export const Input = styled.input `
    display: block;
    width: 300px;
    height: 33px;
    margin: 0 auto 20px auto;
    padding: 0 20px;
    background-color: #91B8F3;
    border-radius: 5px;
    border: none;
    box-shadow: ${props => props.error ? "0px 0px 3px 1px #FF0000" : ""};
    -webkit-box-shadow: ${props => props.error ? "0px 0px 3px 1px #FF0000" : ""}; 

    &::placeholder {
        color: #023867;
    }

    @media (min-width: 768px) {
        width: 384px;
        height: 66px;
        border-radius: 10px;
    }

    @media (min-width: 1024px) {
        width: 800px;
        padding: 0 35px;
    }
`

export const InputMask = styled(InputMaskComponent) `
    display: block;
    width: 300px;
    height: 33px;
    margin: 0 auto 20px auto;
    padding: 0 20px;
    background-color: #91B8F3;
    border-radius: 5px;
    border: none;
    box-shadow: ${props => props.error ? "0px 0px 3px 1px #FF0000" : ""};
    -webkit-box-shadow: ${props => props.error ? "0px 0px 3px 1px #FF0000" : ""};

    &::placeholder {
        color: #023867;
    }

    @media (min-width: 768px) {
        width: 384px;
        height: 66px;
        border-radius: 10px;
    }

    @media (min-width: 1024px) {
        width: 800px;
        padding: 0 35px;
    }
`

export const InputFile = styled.input `
    display: none;
`

export const Button = styled.button `
    ${props => props.disabled ? 'background-color: #DEDEDE' : 'background-color: #0F6FEE' };
    ${props => props.disabled ? '' : 'cursor: pointer'};
    ${props => props.disabled ? 'border: 3px solid #CFCFCF' : 'border: 3px solid #91B8F3'};
    color: white;
    margin: 50px auto;
    display: block;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    outline: none;
    
    &:hover {
        background-color: #0D67DE;
    }
    
    @media (min-width: 768px) {
        ${props => props.disabled ? 'border: 6px solid #CFCFCF' : 'border: 6px solid #91B8F3'};
        border-radius: 10px;
        height: 60px;
    }
`

export const Text = styled.p `
    font-size: 14px;
    color: #ADAFB2;
    text-align: left;
    margin: 0 auto 0 auto;

    @media (min-width: 768px) {
        font-size: 16px;
    }
`
export const ErrorMessage = styled.p `
    font-size: 14px;
    color: #ce1127;
    text-align: left;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        font-size: 16px;
    }
`

export const ReactLoadingComponent = styled(ReactLoading) `
    margin: 0 auto 30px auto;
    ${props => props.loading ? 'display: block' : 'display: none'};
`