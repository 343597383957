import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
    padding: 22px;
    background-color: #DEDEDE;
`

export const Title = styled.p`
    color: #67007D;
    font-size: 25px;
    margin: 50px 0 30px 0;
`

export const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    max-width: 300px;
    margin: 0 auto;

    @media (min-width: 768px) {
        max-width: 350px;
    }
`

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border: 2px solid #67007D;
    border-radius: 10px;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;

    &:hover {
        background-color: #67007D;

        svg {
            fill: white;
            stroke: white;
        }
    }

    @media (min-width: 768px) {
        width: 60px;
        height: 60px;
        border-radius: 16px;
    }
`


export const Icon = styled.svg`
    width: 20px;
    height: 20px;
    fill: #67007D;

    @media (min-width: 768px) {
        width: 40px;
        height: 40px;
    }
`

export const LogoTQI = styled.img`
    margin: 50px auto;
    width: 185px;
    height: 73px;
    cursor: pointer;
`