import styled from 'styled-components'

export const Wrapper = styled.div `
    height: 32px;
    display: flex;
    align-items: center;
    cursor: default;
    cursor: pointer;
`

export const Indicator = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 7px;
    border: 2px solid #0F6FEE;
    margin-right: 8px;
    ${props => props.value ? 'background-color: #0F6FEE' : ''}

`

export const Label = styled.label `
    font-size: 14px;
    color: #ADAFB2;
    text-align: left;
    cursor: pointer;
`