import { React, useRef } from 'react'
import MetaTags from 'react-meta-tags';
import HeaderHero from '../../components/HeaderHero/HeaderHero'
import JobHeader from '../../components/JobHeader/JobHeader'
import Form from '../../components/Form/Form'
import Footer from '../../components/Footer/Footer'
import {
    Section,
    DividerBlue,
    Text,
    Title,
} from './Job.styles'
import jobsList from '../../jobsList.json'
import jobsListBank from '../../jobsListBank.json'

function JobView () {

    const path = window.location.pathname.split('-');
    const id = path[path.length -1 ];
    const vaga = jobsList.filter((job) => job.id === id )[0] || jobsListBank.filter((job) => job.id === id )[0];
    const scrollRef = useRef();

    function scrollToRef () {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return <div>
        <MetaTags>
            <title>{vaga.name}</title>
            <meta property="og:title" content={vaga.name + " - Vem Pra TQI!"} />
        </MetaTags>
        <Section color="pink">
            <HeaderHero />
            <JobHeader jobName={vaga.name} local={vaga.local} scrollToRef={scrollToRef} />
        </Section>

        <Section color="white">
            <br /><br /><br /><br />
            <Title>O que você vai encontrar por aqui:</Title>
            <Text>
                Que incrível que você está aqui e tem interesse em fazer parte dos nossos times. A TQI é feita para você, sim você que quer ousar sem receio e vivenciar grandes desafios. Você fará parte de times multidisciplinares e de alta performance, em um ambiente descontraído, dinâmico e colaborativo que te proporcionará constantes aprendizados e desenvolvimento.
            </Text><br/>
            <Text>
                Aqui, você encontrará liberadade e autonomia, atuando com metodologias ágeis em projetos inspiradores. Sua voz e sua contribuição são muito importantes para nós, para a construção dos nossos produtos e também para nossas tomadas de decisões.
            </Text><br/>
            <Text>
                Não tenha receio em pedir ajuda e compartilhar novas ideias, é assim que conseguimos transformar insights em soluções extraordinárias, somos um time e juntos respiramos inovação.
            </Text><br/>
            <Text>
                E ainda vamos além do código, aqui você aprende e também tem a oportunidade de ensinar, construir e evoluir, na TQI você será protagonista da sua carreira.
            </Text><br/>
            <DividerBlue />
            
            {vaga.mandatoryKnowledge 
                ?   <>
                        <Title>O que você precisa saber:</Title>
                        <Text
                            dangerouslySetInnerHTML={{ __html: vaga.mandatoryKnowledge}}
                        />
                        <DividerBlue />
                    </>
                : ""
            }

            {vaga.differential 
                ?   <>
                        <Title>Será um diferencial para você:</Title>
                        <Text
                            dangerouslySetInnerHTML={{ __html: vaga.differential}}
                        />
                        <DividerBlue />
                    </>
                : ""
            }


            {/* <Title>Sobre a TQI</Title>
            <Text>
                Há mais de 28 anos, transformamos o negócio de nossos clientes por meio de soluções ágeis em TI.
            </Text>
            <Text>
                A gente valoriza e respeita nosso time sempre, em toda sua pluralidade, e formamos times de tecnologia com brilho no olhar, sensibilidade, vontade e garra para transformar negócios.
            </Text>
            <Text>
                Trabalhamos para oferecer um excelente ambiente de trabalho a todo nosso time de profissionais.
            </Text>
            <DividerBlue /> */}

            <div></div>
            <Title ref={scrollRef}>Quero me candidatar!</Title>
            <Text>Dar o primeiro passo é fácil! Basta preencher as informações abaixo.</Text>
            <Form />

        </Section>
        <Footer />
    </div>
}

export default JobView
