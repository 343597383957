import React from 'react'
import { Indicator, Wrapper, Label } from './Checkbox.styles'

function Checkbox ({ value, title, onClick }) {
    return <Wrapper onClick={onClick}>
        <Indicator value={value} />
        <Label>{ title }</Label>
    </Wrapper>
}

export default Checkbox